import React, { useState } from 'react';  
import { MDBBtn, MDBCol, MDBContainer, MDBFile, MDBRow } from 'mdb-react-ui-kit';
import skyhostImage from './images/skyhost.png';
import './App.css';
import axios from 'axios';
import {Buffer} from 'buffer';
import { useAuth0 } from "@auth0/auth0-react";

//import dotenv from 'dotenv';
//dotenv.config();

function App() {
/*Configure keycloak*/
  // setup a state variable to hold the file
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("Svar fra serveren: Der er ikke sendt noget endnu");
  const { isAuthenticated, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const logoutHandler = () => {
    logout({ returnTo: window.location.origin });
  }
  
  const onFileChange = (e) => {
    let comingFile = e.target.files[0];
    setFile(comingFile);
  };

  const onFileUpload = async () => {
    //console.log(file);
    const reader = new FileReader();
    //if file is not null then read the file as data url and send the file to the server using axios post request
    if (file) {
      // 1.5.2. Read the file using readasarraybuffer
      reader.readAsArrayBuffer(file);
      // 1.5.3. Add an event listener to the FileReader object
      reader.addEventListener('load', async (e) => {
        // 1.5.4. Get the file data from the event
        const fileData = e.target?.result;
        // 1.5.5. Check if the file data is not null and if filedata is not of type string
        if (fileData && typeof fileData !== 'string') {
          // 1.5.6. Convert the file data to a Buffer
          const buffer = Buffer.from(fileData);
          const base64data = buffer.toString('base64');
          //console.log(base64data);

          /*
          Send the base64 data to the server using axios post request, 
          as json data in the body of the request, 
          where the key is excelFile and the value is the base64 data
          */
         //const url = 'http://localhost:3001/excel';
         // process.env.REACT_APP_BACKEND_SERVER + ':' + process.env.REACT_APP_BACKEND_PORT + process.env.REACT_APP_EXTENSION;
         let url = 'https://skyhost.doweb.dk/backend/excel' //process.env.REACT_APP_BACKEND_SERVER + process.env.REACT_APP_EXTENSION;

          try {
            const token = await getAccessTokenSilently({audience: 'https://localhost:8084'});
            console.log("jwt access token");
            console.log(token);
            axios
              .post(url, {
              excelFile: base64data,
              }, {
                headers: {
                  Authorization: `Bearer ${token}` // Add token to request header
                }
              })
              .then((res) => {
                if(res.status === 200){
                  setStatus("Svar fra serveren: Fil uploadet successfuldt!");
                }
                else {
                  setStatus("Svar fra serveren: Serverfejl! Send venligst følgende til din systemadministrator" + res.data.msg);
                }
                console.log(res);
              })
              .catch((err) => {
                setStatus("Svar fra serveren: Serverfejl! Send venligst følgende til din systemadministrator" + err);
                console.log(err);
              });
            } catch (err) {
              console.error(err);
            }
          }
      });
    }
  };
  if(isAuthenticated){
    return (
      <MDBContainer>
      <MDBRow>
        <MDBCol xxl='4' xl='3' lg='2' md='1' size='12'></MDBCol>
        <MDBCol xxl='4' xl='5' lg='6' md='7' sm='10' size='12'>
          <div className='p-2'></div>
          <figure className='figure'>
          <img
            src={skyhostImage}
            className='figure-img img-fluid rounded shadow-3 mb-1'
            alt='...'
          />
          </figure>
          <MDBRow className=''>
            <MDBCol size='12'>
              <MDBContainer className="text-center text-white bg-primary bg-gradient p-2 ">
                Velkommen til Skyhosts Excel-Periodiserings-Ark Uploader
              </MDBContainer>
            </MDBCol>
          </MDBRow>
          <div className='p-2'></div>
          <MDBRow>
            <MDBCol size='9'>
              <MDBFile name="sampleName" id='formFileSm' onChange={onFileChange} />
            </MDBCol>
            <MDBCol size='3'>
              <MDBBtn className='me-1' onClick={onFileUpload}>Upload</MDBBtn>
            </MDBCol>
          </MDBRow>
          <div className='p-2'></div>
          <MDBContainer fluid className=" bg-dark d-flex p-1 shadow-2-strong rounded-4 ">
          <MDBContainer className=" bg-light d-xxl-inline-flex p-3 shadow-2-strong rounded-4 ">
            {status}
          </MDBContainer>
        </MDBContainer>
        <div className='p-2'></div>
        <MDBBtn className='me-1' color='danger' onClick={logoutHandler}>Logout</MDBBtn>
        </MDBCol>
        <MDBCol xxl='4' xl='3' lg='2' md='1' size='12'></MDBCol>
      </MDBRow>
    </MDBContainer>
    )} else return (
      <div>
      <p>Initializing Auth0</p>
      <button onClick={() => loginWithRedirect()}>Log In</button>
    </div>
    )
}
export default App;